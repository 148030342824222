body {
    font: 14px "Century Gothic", Futura, sans-serif;
    margin: 5px;
  }


  .App {
    text-align: center;
  }
  
  
  header{
    background-color: #0c0c01;
    height: 200%;
    color:#fafaf4;
    font: oblique bold 120% cursive;
    font-size: xx-large;
    text-align: center;
  }

  .nav-link--active {
    /* font-weight: bold; */
    color: black !important;
    /* background: #0058ff; */
    /* border-radius: 5px; */
  }


  main > .container {
    padding: 60px 15px 0;
  }

  .footer-content, .div-left, .div-righ{
    /* margin-left: 5px; */
    padding-left: 5px;
    color: white;
    height: 20px;
  }

  .div-left{
    display: block;
    float: left;
  }
  .div-righ{
    display: block;
    float: right;
  }

  .primero{
    margin-right: 5px;
    padding-right: 5px;
  }

  .clear{
    /* clear: both;
    border: none;
    height: 0; */
  }

  .content{
    min-height: 300px;
    margin: auto 10px;
        text-align: center;
  }
  
  ol, ul {
    padding-left: 30px;
  }
  
  .board-row:after {
    clear: both;
    content: "";
    display: table;
  }
  
  .status {
    margin-bottom: 10px;
  }
  
  .square {
    background: #fff;
    border: 1px solid #999;
    float: left;
    font-size: 24px;
    font-weight: bold;
    line-height: 34px;
    height: 34px;
    margin-right: -1px;
    margin-top: -1px;
    padding: 0;
    text-align: center;
    width: 34px;
  }
  
  .square:focus {
    outline: none;
  }
  
  .kbd-navigation .square:focus {
    background: #ddd;
  }
  
  .game {
    display: flex;
    flex-direction: row;
  }
  
  .game-info {
    margin-left: 20px;
  }
  